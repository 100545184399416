<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
        color="error"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          {{ error }}
        </div>
      </v-banner>
    </template>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          icon="mdi-web"
          color="secondary"
        >
          <template v-if="allWebsites">
            <v-row>
              <v-col>
                <v-alert
                  icon="mdi-information"
                  border="top"
                  dense
                  color="blue"
                  elevation="6"
                  :text="true"
                >
                  Select the Google Analytics website that you want to associate to this account below:
                </v-alert>
                <v-select
                  v-model="selectedWebsite"
                  :items="allWebsites"
                  item-text="description"
                  item-value="website_id"
                  label="Google Websites"
                  outlined
                  @change="selectWebsite"
                />
              </v-col>
            </v-row>
          </template>
          <v-img
            :src="signInWithGoogleImage"
            width="191"
            style="cursor: pointer;"
            @click="signInWithGoogle()"
          />
        </base-material-card>
      </v-col>
    </v-row>

    <template v-if="showNextStepsDialog">
      <v-row justify="center">
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="v-card--wizard"
            elevation="12"
            max-width="700"
          >
            <v-card-title class="justify-center display-2 pt-5">
              Next steps
            </v-card-title>

            <div class="text-center display-1 font-weight-light mb-6">
              Here is what will happen next:
            </div>

            <div class="my-6" />

            <v-card-text>
              1. We will examine your Google Analytics data and determine what marketing vendors you are using.
            </v-card-text>

            <v-card-actions class="pb-4 pa-4">
              <v-spacer />

              <v-btn
                color="success"
                class="white--text"
                min-width="125"
                @click="showNextStepsDialog = false"
              >
                Dismiss
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showProviderDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Website Provider Selected
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="info"
            >
              We detected that your website provider is <strong> {{ selectedProvider }}. </strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            class="white--text"
            min-width="125"
            @click="closeProviderDialog()"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showUnknownProviderDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Unknown Website Provider
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="warning"
            >
              We weren't able to determine which website provider you are using. <br> <br> But don't worry, we're already working on adding it. We will let you know once it's all setup on our side.
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="showUnknownProviderDialog = false"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import AccountService from '@/services/AccountService.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'WebsiteConfig',
    data: () => ({
      code: '',
      error: '',
      loading: false,
      loadingMessage: 'Authenticating',
      allWebsites: null,
      selectedWebsite: null,
      allProviders: [],
      selectedProvider: null,
      showProviderDialog: false,
      showUnknownProviderDialog: false,
      showNextStepsDialog: false,
      loadedFromGoogle: false,
      signInWithGoogleImage: '/images/btn_google_signin_dark_normal_web.png',
      authenticationUrl: 'https://accounts.google.com/o/oauth2/auth?client_id=656011718527-iuj0r3ub86fiv8n8camq0llsgkmdo6mq.apps.googleusercontent.com&approval_prompt=force&access_type=offline&redirect_uri=https://dms.dealerinsights.com/google_confirmation&response_type=code&scope=https://www.googleapis.com/auth/analytics.readonly',
    }),
    computed: {
      ...mapGetters(['accountId', 'googleWebsiteId', 'accountName']),
    },
    created () {
      this.code = this.$route.query.code
      if (this.code) { this.loadedFromGoogle = true } else this.loadedFromGoogle = false
      if (this.googleWebsiteId) { this.selectedWebsite = this.googleWebsiteId.toString() }
      this.getGoogleWebsites()
      this.googleConfirmation()
    },
    methods: {
      googleConfirmation () {
        if (this.code) {
          this.error = ''
          this.loading = true
          AccountService.googleConfirmation(this.code)
            .then(response => {
              this.loading = false
              this.getGoogleWebsites()
            })
            .catch(error => {
              this.loading = false
              var status = error.response.status.toString()
              if (status === '400') {
                this.error = 'Invalid Grant'
              } else {
                this.error = error.response.statusText
              }
            })
        }
      },
      getGoogleWebsites () {
        this.error = ''
        this.loadingMessage = 'Retrieving list of Google websites'
        this.loading = true
        AccountService.getGoogleWebsites()
          .then(response => {
            console.log(response.data)
            this.loading = false
            this.allWebsites = response.data
          })
          .catch(error => {
            this.loading = false
            var status = error.response.status.toString()
            if (status === '400') {
              this.error = 'Invalid Grant'
            } else {
              this.error = error.response.statusText
            }
          })
      },
      getWebsiteProvider () {
        this.error = ''
        this.loadingMessage = 'Detecting website provider'
        this.loading = true
        var websiteId = this.allWebsites.filter(item => item.website_id === this.selectedWebsite)[0].website_id
        AccountService.getWebsiteProvider(websiteId)
          .then(response => {
            this.selectedProvider = response.data
            this.loading = false
            if (this.selectedProvider) {
              this.updateWebsiteProvider()
              this.showProviderDialog = true
            } else { this.showUnknownProviderDialog = true }
          })
          .catch(error => {
            this.loading = false
            var status = error.response.status.toString()
            if (status === '400') {
              this.error = 'Invalid Grant'
            } else {
              this.error = error.response.statusText
            }
          })
      },
      getSupportedWebsiteProviders () {
        this.error = ''
        this.loadingMessage = 'Retrieving list of website providers'
        this.loading = true
        AccountService.getSupportedWebsiteProviders()
          .then(response => {
            this.loading = false
            this.allProviders = response.data
          })
          .catch(error => {
            this.loading = false
            var status = error.response.status.toString()
            if (status === '400') {
              this.error = 'Invalid Grant'
            } else {
              this.error = error.response.statusText
            }
          })
      },
      selectWebsite () {
        this.error = ''
        this.loadingMessage = 'Saving website information'
        this.loading = true
        AccountService.setGoogleWebsite(this.selectedWebsite)
          .then(response => {
            this.$store.dispatch('set_google_website_id', this.selectedWebsite)
            this.loading = false
            this.getWebsiteProvider()
          })
          .catch(error => {
            this.loading = false
            var status = error.response.status.toString()
            if (status === '400') {
              this.error = 'Invalid Grant'
            } else {
              this.error = error.response.statusText
            }
          })
      },
      updateWebsiteProvider () {
        this.error = ''
        this.loadingMessage = 'Saving website provider'
        this.loading = true
        AccountService.updateWebsiteProvider(this.selectedProvider)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            var status = error.response.status.toString()
            if (status === '400') {
              this.error = 'Invalid Grant'
            } else {
              this.error = error.response.statusText
            }
          })
      },
      closeProviderDialog () {
        this.showProviderDialog = false
        if (this.loadedFromGoogle) { this.showNextStepsDialog = true }
      },
      signInWithGoogle () {
        this.signInWithGoogleImage = '/images/btn_google_signin_dark_pressed_web.png'
         window.location.href = "https://accounts.google.com/o/oauth2/auth?client_id=656011718527-iuj0r3ub86fiv8n8camq0llsgkmdo6mq.apps.googleusercontent.com&approval_prompt=force&access_type=offline&redirect_uri=https://dms.dealerinsights.com/google_confirmation&response_type=code&scope=https://www.googleapis.com/auth/analytics.readonly"
      }
    },
  }
</script>
