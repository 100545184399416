<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Settings"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <v-tabs
      fixed-tabs
      background-color="grey darken-2"
      dark
    >
      <template v-if="appName === 'insights'">
        <v-tab>
          Alert Options
        </v-tab>
      </template>
      <template v-if="appName === 'contact'">
        <v-tab>
          Notifications
        </v-tab>
        <v-tab>
          Messaging
        </v-tab>
      </template>
      <v-tab>
        Website
      </v-tab>
      <template v-if="appName === 'insights'">
        <v-tab-item>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-2">
                    Vehicles Missing From Website
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the maximum number of vehicles that can be missing from the website before an alert is generated:
                    </v-subheader>
                    <v-slider
                      v-model="settings.vehicles_not_website_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="100"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.vehicles_not_website_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="100"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Vehicles Needing Price Updates
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the maximum number of vehicles that can be needing price updates before an alert is generated:
                    </v-subheader>
                    <v-slider
                      v-model="settings.vehicles_needing_price_updates_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="100"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.vehicles_needing_price_updates_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="100"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-2">
                    Vehicles Needing Photos
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the maximum number of vehicles that can be needing photos before an alert is generated:
                    </v-subheader>
                    <v-slider
                      v-model="settings.vehicles_needing_photos_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="100"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.vehicles_needing_photos_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="100"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Vehicles With No Activity
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the maximum number of vehicles that can have no activity before an alert is generated:
                    </v-subheader>
                    <v-slider
                      v-model="settings.vehicles_with_no_activity_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="100"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.vehicles_with_no_activity_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="100"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Leads
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the minimum number of leads per vehicle that will change the alert color:
                    </v-subheader>
                    <v-slider
                      v-model="settings.lead_color_green"
                      thumb-label="always"
                      thumb-color="green lighten-1"
                      color="green lighten-1"
                      max="100"
                      min="1"
                      label="Green Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.lead_color_orange"
                      thumb-label="always"
                      thumb-color="orange"
                      color="orange"
                      max="100"
                      min="1"
                      label="Orange Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Photos
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the minimum number of photos per vehicle that will change the alert color:
                    </v-subheader>
                    <v-slider
                      v-model="settings.photo_color_green"
                      thumb-label="always"
                      thumb-color="green lighten-1"
                      color="green lighten-1"
                      max="100"
                      min="1"
                      label="Green Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.photo_color_orange"
                      thumb-label="always"
                      thumb-color="orange"
                      color="orange"
                      max="100"
                      min="1"
                      label="Orange Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Age
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the age of a vehicle before an alert is generated:
                    </v-subheader>
                    <v-slider
                      v-model="settings.age_color_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="180"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.age_color_orange"
                      thumb-label="always"
                      thumb-color="orange"
                      color="orange"
                      max="180"
                      min="1"
                      label="Orange Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.age_color_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="180"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <template>
                <v-card>
                  <v-card-title class="headline font-weight-regular light-blue darken-2 white--text pb-1">
                    Days Since Price Change
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0 mb-8">
                      Select the minimum number of days since last price change that will change the alert color:
                    </v-subheader>
                    <v-slider
                      v-model="settings.days_since_price_change_yellow"
                      thumb-label="always"
                      thumb-color="yellow accent-4"
                      color="yellow accent-4"
                      max="100"
                      min="1"
                      label="Yellow Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.days_since_price_change_orange"
                      thumb-label="always"
                      thumb-color="orange"
                      color="orange"
                      max="100"
                      min="1"
                      label="Orange Alert"
                      class="mb-6"
                      @end="updateSettings()"
                    />
                    <v-slider
                      v-model="settings.days_since_price_change_red"
                      thumb-label="always"
                      thumb-color="red darken-1"
                      color="red darken-1"
                      max="100"
                      min="1"
                      label="Red Alert"
                      @end="updateSettings()"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-tab-item>
      </template>
      <template v-if="appName === 'contact'">
        <v-tab-item>
          <template v-if="!notificationPermissionGranted">
            <v-row
              dense
              justify="center"
            >
              <v-col
                cols="12"
                md="8"
              >
                <v-alert
                  icon="mdi-information"
                  border="top"
                  dense
                  color="warning"
                  elevation="6"
                  :text="true"
                >
                  If you want to receive notifications on your browser when customers are calling or texting, please click the Enable Push Notifications button.
                  <div class="text-right">
                    <v-btn
                      color="primary"
                      @click="requestNotificationPermission()"
                    >
                      Enable Push Notifications
                    </v-btn>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <v-row dense>
            <v-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
            >
              <base-material-card
                title=""
                color="#37474F"
              >
                <template v-slot:heading>
                  <div class="display-2 font-weight-medium text-center">
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-clock
                    </v-icon>
                    Select the starting time to send messages
                  </div>
                </template>
                <v-time-picker
                  v-model="textingStartTime"
                  ampm-in-title
                  format="ampm"
                  full-width
                  @change="savePhoneSettings"
                  @click:hour="savePhoneSettings"
                  @click:minute="savePhoneSettings"
                  @update:period="savePhoneSettings"
                />
                <template v-slot:actions>
                  <div class="display-1 font-weight-black">
                    <v-switch
                      v-model="textingEnabled"
                      :label="textingEnabled ? 'Texting is enabled' : 'Texting is disabled'"
                      color="success"
                      @change="savePhoneSettings"
                    />
                  </div>
                </template>
              </base-material-card>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
            >
              <base-material-card
                title=""
                color="#37474F"
              >
                <template v-slot:heading>
                  <div class="display-2 font-weight-medium text-center">
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-clock
                    </v-icon>
                    Select the ending time to send messages
                  </div>
                </template>
                <v-time-picker
                  v-model="textingEndTime"
                  ampm-in-title
                  format="ampm"
                  full-width
                  @change="savePhoneSettings"
                  @click:hour="savePhoneSettings"
                  @click:minute="savePhoneSettings"
                  @update:period="savePhoneSettings"
                />
                <template v-slot:actions>
                  <div
                    class="display-1 font-weight-black"
                    style="height: 66px"
                  />
                </template>
              </base-material-card>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
            >
              <base-material-card
                title=""
                color="#37474F"
              >
                <template v-slot:heading>
                  <div class="display-2 font-weight-medium text-center">
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-account
                    </v-icon>
                    Select the days of the week to send messages & CRM Address
                  </div>
                </template>
                <v-autocomplete
                  v-model="textingDaysOfWeek"
                  :items="allDaysOfWeek"
                  outlined
                  chips
                  small-chips
                  label="Days of week to send messages"
                  multiple
                  class="mt-5"
                  @change="savePhoneSettings"
                />
                <v-text-field
                  v-model="crmAddress"
                  color="blue"
                  label="Enter the email address of the CRM so that we can push leads to it"
                  append-icon="mdi-email"
                  clearable
                  outlined
                  style="height: 272"
                  @blur="savePhoneSettings"
                />
                <template v-slot:actions>
                  <div
                    class="display-1 font-weight-black"
                    style="height: 66px"
                  />
                </template>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
            >
              <base-material-card
                title=""
                color="#37474F"
              >
                <template v-slot:heading>
                  <div class="display-2 font-weight-medium text-center">
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-phone
                    </v-icon>
                    Texting Phone Numbers
                  </div>
                </template>
                <template v-if="user.phone">
                  <v-row>
                    <v-col>
                      <template v-if="!callTrackingNumber">
                        <v-btn
                          color="success"
                          @click="registerPhoneNumber()"
                          :disabled = "loading"

                        >
                          Register Sales Phone Number
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-alert
                          icon="mdi-information"
                          border="top"
                          dense
                          color="blue"
                          elevation="6"
                          :text="true"
                        >
                          Your texting phone number for Sales Missions is <strong>{{ formatPhoneNumber(callTrackingNumber) }}</strong>
                        </v-alert>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="voiceForwardingNumber"
                        color="blue"
                        label="Enter the phone number to forward inbound Sales phone calls to"
                        append-icon="mdi-phone"
                        clearable
                        outlined
                        @blur="savePhoneSettings"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <template v-if="!serviceCallTrackingNumber">
                        <v-btn
                          color="success"
                          @click="registerServicePhoneNumber()"
                          :disabled = "loading"
                        >
                          Register Service Phone Number
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-alert
                          icon="mdi-information"
                          border="top"
                          dense
                          color="blue"
                          elevation="6"
                          :text="true"
                        >
                          Your texting phone number for Service Missions is <strong>{{ formatPhoneNumber(serviceCallTrackingNumber) }}</strong>
                        </v-alert>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="serviceVoiceForwardingNumber"
                        color="blue"
                        label="Enter the phone number to forward inbound Service phone calls to"
                        append-icon="mdi-phone"
                        clearable
                        outlined
                        @blur="savePhoneSettings"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row>
                    <v-col>
                      <v-alert
                        icon="mdi-alert-decagram"
                        border="top"
                        dense
                        color="orange darken-3"
                        elevation="6"
                        :text="true"
                        prominent
                      >
                        You need to provide your mobile phone number before registering texting phone numbers.<br/><br/>Click
                        <router-link
                          style="cursor: pointer; color: blue"
                          :to="{ name: 'User Profile' }"
                        >
                          here
                        </router-link>
                        to update your mobile phone number.
                      </v-alert>
                    </v-col>
                  </v-row>                  
                </template>
                <template v-slot:actions>
                  <div
                    class="display-1 font-weight-black"
                    style="height: 66px"
                  />
                </template>
              </base-material-card>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
            >
              <base-material-card
                title=""
                color="#37474F"
              >
                <template v-slot:heading>
                  <div class="display-2 font-weight-medium text-center">
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-email
                    </v-icon>
                    Notification Settings
                  </div>
                </template>
                <v-row>
                  <v-col
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <v-card
                      class="mx-auto"
                      max-width="600"
                    >
                      <v-card-text>
                        <v-row
                          class="mb-4"
                          justify="space-between"
                        >
                          <v-col class="text-left">
                            <span class="subheading font-weight-light mr-1">Send me an email alert when I have more than <strong>{{ numberTextsForEmailAlert }}</strong> unread messages.</span>
                          </v-col>
                        </v-row>

                        <v-slider
                          v-model="numberTextsForEmailAlert"
                          :disabled="emailAlertsDisabled"
                          color="primary"
                          track-color="grey"
                          always-dirty
                          min="1"
                          max="20"
                          @change="savePhoneSettings"
                        >
                          <template v-slot:prepend>
                            <v-icon
                              color="secondary"
                              @click="decrement"
                            >
                              mdi-minus
                            </v-icon>
                          </template>

                          <template v-slot:append>
                            <v-icon
                              color="secondary"
                              @click="increment"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                        </v-slider>
                        <v-checkbox
                          v-model="emailAlertsDisabled"
                          color="secondary"
                          @change="savePhoneSettings"
                        >
                          <template v-slot:label>
                            <span class="text-no-wrap">Do not send me any email alerts.</span>
                          </template>
                        </v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <v-card
                      class="mx-auto"
                      max-width="600"
                    >
                      <v-card-text>
                        <v-row
                          class="mb-4"
                          justify="space-between"
                        >
                          <v-col class="text-left">
                            <span class="subheading font-weight-light mr-1">Send me an text alert when I have unread messages for longer than <strong>{{ numberMinutesForTextAlert }}</strong> minutes.</span>
                          </v-col>
                        </v-row>

                        <v-slider
                          v-model="numberMinutesForTextAlert"
                          :disabled="textAlertsDisabled"
                          color="primary"
                          track-color="grey"
                          always-dirty
                          min="1"
                          max="20"
                          @change="savePhoneSettings"
                        >
                          <template v-slot:prepend>
                            <v-icon
                              color="secondary"
                              @click="decrementText"
                            >
                              mdi-minus
                            </v-icon>
                          </template>

                          <template v-slot:append>
                            <v-icon
                              color="secondary"
                              @click="incrementText"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                        </v-slider>
                        <v-checkbox
                          v-model="textAlertsDisabled"
                          color="secondary"
                          @change="savePhoneSettings"
                        >
                          <template v-slot:label>
                            <span class="text-no-wrap">Do not send me any text alerts.</span>
                          </template>
                        </v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <template v-slot:actions>
                  <div
                    class="display-1 font-weight-black"
                    style="height: 66px"
                  />
                </template>
              </base-material-card>
            </v-col>
          </v-row>


        </v-tab-item>
        <v-tab-item>
          <v-row dense>
            <v-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <base-material-card
                title=""
                color="#37474F"
                style="background-color: #FAFAFA"
              >
                <template v-slot:heading>
                  <div
                    class="display-2 font-weight-medium text-center"
                    style="font-size: 22px !important"
                  >
                    <v-icon
                      large
                      color="primary"
                    >
                      mdi-lifebuoy
                    </v-icon>
                    <span class="white--text">
                      Save Attempts
                    </span>
                  </div>
                </template>
                <v-alert
                  border="top"
                  color="green lighten-2"
                  dark
                >
                  <strong>Save Attempts are follow-up messages that will be automatically sent to a customer after a certain number of days have passed since our last message to them.</strong>
                </v-alert>
                <v-row dense>
                  <v-col>
                    <v-card>
                      <v-data-table
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        :headers="headers"
                        :items="saveAttempts"
                        :items-per-page="50"
                        hide-default-footer
                        :sort-by="['days']"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="editSaveAttempt(item)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            @click="deleteSaveAttempt(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                      <v-row dense>
                        <v-col class="text-right">
                          <v-btn
                            color="primary"
                            @click="addNewSaveAttempt()"
                          >
                            Add New Save Attempt
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </base-material-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </template>
      <v-tab-item>
        <website-config />
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSaveAttemptDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Create Save Attempt
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-text-field
                v-model="currentSaveAttempt.days"
                label="How many days since the last contact date to send this message?"
                outlined
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-textarea
                v-model="currentSaveAttempt.message"
                label="What message do you want to send?"
                outlined
                rows="2"
              />
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="saveSaveAttempt()"
          >
            Save
          </v-btn>
          <v-btn
            @click="showSaveAttemptDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'
  import AccountService from '@/services/AccountService.js'
  import WebsiteConfig from '@/components/WebsiteConfig.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import NetworkService from '@/services/NetworkService.js'

  export default {
    name: 'Settings',
    components: {
      WebsiteConfig,
      ErrorMessage
    },
    mixins: [formatterMixin, countColorsMixin],
    data () {
      return {
        loading: false,
        loadingMessage: 'Loading',
        error: '',
        showErrorDialog: false,
        settings: {},
        notificationPermissionGranted: false,
        textingStartTime: '9:00',
        textingEndTime: '17:00',
        allDaysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        textingDaysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        textingEnabled: true,
        voiceForwardingNumber: '',
        serviceVoiceForwardingNumber: '',
        callTrackingNumber: null,
        serviceCallTrackingNumber: null,
        numberTextsForEmailAlert: null,
        emailAlertsDisabled: false,
        textAlertsDisabled: false,
        numberMinutesForTextAlert: null,
        crmAddress: '',
        saveAttempts: [],
        showSaveAttemptDialog: false,
        headers: [
          { text: 'Days Since Last Contact', value: 'days' },
          { text: 'Message', value: 'message' },
          { text: 'Actions', value: 'actions' },
        ],
        currentSaveAttempt: {},
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'alertSettings', 'permissions', 'appName', 'user']),
    },
    watch: {
      accountId () {
        this.loadSettings()
      },
      alertSettings () {
        this.loadSettings()
      },
    },
    created () {
      this.loadSettings()
      this.loadPhoneSettings()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadSettings: function () {
        this.settings = this.alertSettings
        this.notificationPermissionGranted = (Notification.permission === 'granted')
      },
      updateSettings: function () {
        AccountService.saveAlertSettings(this.settings)
      },
      requestNotificationPermission: function () {
        const v = this
        Notification.requestPermission(function (status) {
          v.notificationPermissionGranted = (status === 'granted')
        })
      },
      loadPhoneSettings: function () {
        this.loadingMessage = 'Loading Attempting Contact Settings'
        this.loading = true
        ChatovateService.getChatovatePhoneSetting()
          .then(response => {
            this.textingStartTime = response.data.texting_start_time
            this.textingEndTime = response.data.texting_end_time
            this.textingDaysOfWeek = response.data.texting_days_of_week
            this.textingEnabled = response.data.texting_enabled
            this.voiceForwardingNumber = response.data.voice_forwarding_number
            this.serviceVoiceForwardingNumber = response.data.service_voice_forwarding_number
            this.callTrackingNumber = response.data.call_tracking_number
            this.serviceCallTrackingNumber = response.data.service_call_tracking_number
            this.numberTextsForEmailAlert = response.data.number_texts_for_email_alert
            this.emailAlertsDisabled = response.data.email_alerts_disabled
            this.numberMinutesForTextAlert = response.data.number_minutes_for_text_alert
            this.textAlertsDisabled = response.data.text_alerts_disabled
            this.crmAddress = response.data.crm_address
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
        this.loadSaveAttempts()
      },
      loadSaveAttempts () {
        this.loadingMessage = 'Loading'
        this.loading = true
        ChatovateService.getSaveAttempts()
          .then(response => {
            this.saveAttempts = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      savePhoneSettings: function () {
        this.loadingMessage = 'Saving Attempting Contact Settings'
        this.loading = true
        var data = {
          textingStartTime: this.textingStartTime,
          textingEndTime: this.textingEndTime,
          textingDaysOfWeek: this.textingDaysOfWeek,
          textingEnabled: this.textingEnabled,
          voiceForwardingNumber: this.voiceForwardingNumber,
          serviceVoiceForwardingNumber: this.serviceVoiceForwardingNumber,
          numberTextsForEmailAlert: this.numberTextsForEmailAlert,
          emailAlertsDisabled: this.emailAlertsDisabled,
          numberMinutesForTextAlert: this.numberMinutesForTextAlert,
          textAlertsDisabled: this.textAlertsDisabled,
          crmAddress: this.crmAddress,
        }
        ChatovateService.updateChatovatePhoneSetting(data)
          .then(response => {
            this.saveAttempts = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      registerPhoneNumber: function () {
        this.loadingMessage = 'Registering Sales Phone Number'
        this.loading = true
        ChatovateService.registerPhoneNumber()
          .then(response => {
            this.callTrackingNumber = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      registerServicePhoneNumber: function () {
        this.loadingMessage = 'Registering Service Phone Number'
        this.loading = true
        ChatovateService.registerServicePhoneNumber()
          .then(response => {
            this.serviceCallTrackingNumber = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },      
      decrement () {
        this.numberTextsForEmailAlert--
        this.savePhoneSettings()
      },
      increment () {
        this.numberTextsForEmailAlert++
        this.savePhoneSettings()
      },
      decrementText () {
        this.numberMinutesForTextAlert--
        this.savePhoneSettings()
      },
      incrementText () {
        this.numberMinutesForTextAlert++
        this.savePhoneSettings()
      },
      addNewSaveAttempt () {
        this.currentSaveAttempt.days = 5
        this.currentSaveAttempt.message = ''
        this.showSaveAttemptDialog = true
      },
      deleteSaveAttempt (item) {
        if (confirm('Are you sure you want to delete this Save Attempt?')) {
          this.loading = true
          ChatovateService.deleteSaveAttempt(item.uuid)
            .then(response => {
              this.loading = false
              this.loadSaveAttempts()
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      editSaveAttempt (item) {
        this.currentSaveAttempt.days = item.days
        this.currentSaveAttempt.message = item.message
        this.currentSaveAttempt.id = item.uuid
        this.showSaveAttemptDialog = true
      },
      saveSaveAttempt () {
        if (this.currentSaveAttempt.id) {
          this.updateSaveAttempt()
        } else { this.createSaveAttempt() }
      },
      createSaveAttempt () {
        var data = {
          days: this.currentSaveAttempt.days,
          message: this.currentSaveAttempt.message,
        }
        this.loadingMessage = 'Saving'
        this.loading = true
        ChatovateService.createSaveAttempt(data)
          .then(response => {
            this.loading = false
            this.loadSaveAttempts()
            this.showSaveAttemptDialog = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      updateSaveAttempt () {
        var data = {
          days: this.currentSaveAttempt.days,
          message: this.currentSaveAttempt.message,
          id: this.currentSaveAttempt.id,
        }
        this.loadingMessage = 'Saving'
        this.loading = true
        ChatovateService.updateSaveAttempt(data)
          .then(response => {
            this.loading = false
            this.loadSaveAttempts()
            this.showSaveAttemptDialog = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
